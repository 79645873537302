exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-gatsby-theme-portfolio-minimal-src-templates-article-listing-index-tsx": () => import("./../../../node_modules/gatsby-theme-portfolio-minimal/src/templates/ArticleListing/index.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-portfolio-minimal-src-templates-article-listing-index-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-templates-article-page-js": () => import("./../../../src/templates/ArticlePage.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-bibliografia-js": () => import("./../../../src/templates/Bibliografia.js" /* webpackChunkName: "component---src-templates-bibliografia-js" */),
  "component---src-templates-cultura-js": () => import("./../../../src/templates/Cultura.js" /* webpackChunkName: "component---src-templates-cultura-js" */),
  "component---src-templates-historia-js": () => import("./../../../src/templates/Historia.js" /* webpackChunkName: "component---src-templates-historia-js" */),
  "component---src-templates-lengua-js": () => import("./../../../src/templates/Lengua.js" /* webpackChunkName: "component---src-templates-lengua-js" */),
  "component---src-templates-materiales-js": () => import("./../../../src/templates/Materiales.js" /* webpackChunkName: "component---src-templates-materiales-js" */),
  "component---src-templates-noticias-js": () => import("./../../../src/templates/Noticias.js" /* webpackChunkName: "component---src-templates-noticias-js" */),
  "component---src-templates-pdf-page-js": () => import("./../../../src/templates/PdfPage.js" /* webpackChunkName: "component---src-templates-pdf-page-js" */)
}

